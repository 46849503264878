import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { BLOCKS } from '@contentful/rich-text-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import DestiniBuyButton from 'components/ui/DestiniBuyButton';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText/Server';
import Text from 'components/ui/Text';

import { getReadableMonth } from 'utils';

import styles from './CardPolaroid.module.scss';

const copyOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    className={styles.paragraph}
                    baseTheme="bodySmall"
                    themes={{ large: 'bodyMedium' }}
                >
                    {children}
                </Text>
            );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
            return (
                <Text
                    as="h3"
                    className={styles.label}
                    baseTheme="labelLargeAlt"
                >
                    {children}
                </Text>
            );
        },
    },
};

const CardPolaroid = ({
    date,
    headline,
    copy,
    image,
    cta,
    sizes3Collection,
    __typename,
}) => {
    const formattedDate = date ? new Date(date) : null;

    return (
        <article
            className={cx(styles.root, {
                [styles.productCard]: __typename === 'Product',
            })}
        >
            <div className={styles.inner}>
                <div className={styles.image}>
                    {image && (
                        <Img
                            src={image.url}
                            alt={image.description || ''}
                            fallbackImageWidth={696}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: image.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 696,
                                },
                            ]}
                        />
                    )}
                    {date && (
                        <div className={styles.flag}>
                            <Text
                                as="p"
                                className={styles.month}
                                baseTheme="labelSmallAlt"
                                themes={{ large: 'labelLarge' }}
                            >
                                {`${getReadableMonth(
                                    formattedDate.getUTCMonth()
                                )} `}
                            </Text>
                            <Text
                                as="p"
                                className={styles.day}
                                baseTheme="displayXSmall"
                                themes={{ large: 'displaySmall' }}
                            >
                                {`${formattedDate.getUTCDate()}`}
                            </Text>
                        </div>
                    )}
                </div>
                <div className={styles.content}>
                    <Text
                        as="h3"
                        baseTheme={
                            __typename === 'Product'
                                ? 'headingXSmall'
                                : 'headingSmall2'
                        }
                        themes={{
                            large:
                                __typename === 'Product'
                                    ? 'headingSmall'
                                    : 'displayXSmall',
                        }}
                        className={styles.headline}
                    >
                        {__typename === 'Product'
                            ? `Tillamook ${headline}`
                            : headline}
                    </Text>
                    {copy && (
                        <RichText richText={copy} overrides={copyOverrides} />
                    )}
                    {__typename === 'Product' && sizes3Collection ? (
                        <DestiniBuyButton
                            sku={sizes3Collection.items.map(item => item.sku2)}
                            button={
                                <ButtonCTA
                                    text="Shop Now"
                                    style="filled-blue-ocean"
                                />
                            }
                        />
                    ) : (
                        cta && <ButtonCTA className={styles.cta} {...cta} />
                    )}
                </div>
            </div>
        </article>
    );
};

CardPolaroid.propTypes = {
    __typename: PropTypes.string,
    copy: PropTypes.object,
    cta: PropTypes.object,
    date: PropTypes.string,
    headline: PropTypes.string,
    image: PropTypes.object,
    sizes3Collection: PropTypes.object,
};

export default CardPolaroid;
